import React, { useEffect, useState } from 'react';
import Spacing from '../../Spacing';
import TeamStyle2 from '../../Team/TeamStyle2';
import Pagination from '../../Pagination';

export default function TeamSectionStyle2({ doctors }) {
  const [view, setView] = useState('grid');
  const [active, setActive] = useState('all');
  const [filteredData, setFilteredData] = useState(doctors);
  const [visibleCount, setVisibleCount] = useState(6); // Initial count of visible items

  // Extracting unique categories from doctors data
  const uniqueCategories = [...new Set(doctors.map(doctor => doctor.specialization))];
  
  const handleFilter = specialization => {
    if (specialization === 'all') {
      setFilteredData(doctors);
    } else {
      const filtered = doctors.filter(doctor => doctor.specialization === specialization);
      setFilteredData(filtered);
    }
    setActive(specialization);
    setVisibleCount(6); // Reset visible count when filter changes
  };

  useEffect(() => {
    setFilteredData(doctors);
  }, [doctors]);

  // Function to load more items
  const loadMore = () => {
    setVisibleCount(prevCount => prevCount + 6); // Increase visible count by 6 on each click
  };

  return (
    <div className="container">
      <div className="cs_doctors_heading">
        <div className="cs_isotop_filter cs_style1">
          <ul className="cs_mp0" style={{ gap: '10px' }}>
            <li className={active === 'all' ? 'active' : ''}>
              <span onClick={() => handleFilter('all')} style={{ padding: '7px 12px' }}>All</span>
            </li>
            {uniqueCategories?.map(item => (
              <li className={active === item ? 'active' : ''} key={item}>
                <span onClick={() => handleFilter(item)} style={{ padding: '7px 12px' }}>{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Spacing md="65" />
      <div className={`cs_team_grid cs_${view}_view_wrap`}>
        {filteredData?.slice(0, visibleCount).map((item, index) => (
          <TeamStyle2 {...item} key={index} />
        ))}
      </div>
      
      {visibleCount < filteredData.length && ( // Show "View More" button only if there are more items to load
        <div style={{ textAlign: 'center', marginTop: '20px' }}>

          <button
              type="button"
              className="cs_btn cs_style_1 mt-5"
              onClick={() => loadMore()}
            >
              <span>View More</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </button>
        </div>
      )}
    </div>
  );
}
