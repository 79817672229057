import React, { useEffect, useState } from 'react';
import Pricing from '../../Pricing';
import PricingHorizontal from '../../PricingHorizontal';
import SectionHeading from '../../SectionHeading';
import Spacing from '../../Spacing';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { applyCoupon, checkout, clearCoupon, updateUserData, updatePaymentStatus } from '../../../redux/user/userSlice';
import useAuthToken from '../../../hooks/useAuthToken';
import { WEBSITE_URL } from '../../../config/apiUrls';
import { Link, useNavigate } from 'react-router-dom';

export default function PricingSection({ sectionTitle, plans }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useAuthToken();
  const coupon_discount = useSelector(state => state.user.coupon_discount);
  const checkout_data = useSelector(state => state.user.checkout_data);
  const user = useSelector(state => state.user.user);
  const [prefill, setPrefill] = useState({
    name: user?.user?.name,
    email: user?.user?.email,
    contact: user?.user?.phone,
  });

  const [showPopup, setShowPopup] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [couponError, setCouponError] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [promoCode, setPromoCode] = useState(null);
  const [promoError, setPromoError] = useState(null);
  const [chosenPlan, setChosenPlan] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(false);
  const [showHealthCare, setShowHealthCare] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const openPopUp = (plan) => {
    setShowPopup(true)
    setChosenPlan(plan)
    dispatch(clearCoupon())
  }

  const __applyCoupon = (e) => {
    e.preventDefault();
    dispatch(applyCoupon({
      token: token,
      coupon_code: promoCode,
      plan_id: chosenPlan?.id
    }))
  }

  useEffect(() => {
    if (coupon_discount?.success) {
      setDiscount(coupon_discount?.discount_price);
      setCouponError(false)

    } else {
      setDiscount(0)
      setCouponError(true)
    }
  }, [coupon_discount])

  useEffect(() => {
    if (checkout_data?.success) {
      setShowPopup(false);
      dispatch(updateUserData(token));

      // setShowSuccessModal(true)
    }
  }, [checkout_data]);

  const __getGrandTotal = () => {
    const tax = (parseFloat(chosenPlan?.price) * 18) / 100;
    let total = (parseFloat(chosenPlan?.price) * 12) - parseFloat(discount)

    return Number(total).toFixed(2);;
  }

  const __checkout = () => {
    if (isChecked) {
      const tax = parseFloat(18);

      dispatch(checkout({
        token: token,
        coupon_code: promoCode,
        plan_id: chosenPlan?.id,
        gst: tax
      })).then((response) => {
        let subscription = response?.payload?.subscription;

        if (subscription?.payment_status == 'PENDING') {
          const options = {
            key: 'rzp_live_9pJJfX2lOLNTIJ',
            amount: subscription?.price,
            currency: 'INR',
            name: 'GetAFixMD',
            order_id: subscription?.order_id,
            image: 'https://getafixmd.com/wp-content/uploads/2022/07/favicon-1.png',
            handler: function (response) {
              dispatch(updatePaymentStatus({ token: token, id: subscription?.id, payment_id: response.razorpay_payment_id }));
              navigate('/onboarding')
            },
            modal: {
              ondismiss: function () {
                dispatch(updatePaymentStatus({ token: token, id: subscription?.id, payment_id: null }));
                navigate('/onboarding')
              },
              confirm_close: true
            },
            prefill: {
              name: prefill.name,
              email: prefill.email,
              contact: prefill.contact,
            },
            theme: {
              color: "#D2538C",
            }
          };
          const rzp = new window.Razorpay(options);
          rzp.open();
        }
      });
    } else {
      setError(true);
    }
  }

  return (
    <div className="container">
      <SectionHeading title={sectionTitle} center />
      <Spacing md="30" lg="30" />
      <Modal
        visible={showSuccessModal}
        onOk={() => setShowSuccessModal(false)}
        onCancel={() => setShowSuccessModal(false)}
        footer={null}
      >
        <div className="cs_newsletter cs_style_1">
          <div className='text-center'>
            <img src={'/images/success.png'} alt="Icon" style={{ height: '150px' }} />
          </div>
          <h2 className="cs_newsletter_title text-center mt-3" style={{ fontSize: '25px' }}>Success</h2>
          <div className="cs_fs_10 text-center">
            Thank you for choosing GetAFixMD.
            <br />
            We are at your service from now on.
          </div>
          <div className='mt-4'>
            <a target={'_blank'} href={WEBSITE_URL + 'view-policy/' + user?.user?.active_plan?.id} className="cs_btn cs_style_1" style={{ "position": "unset", "marginTop": "20px", width: '100%', fontWeight: 'bold', fontSize: "16px" }}>
              <span>Download Invoice</span>
            </a>
          </div>
          <div>
            <Link to={'/profile'} href={WEBSITE_URL + 'view-policy/' + user?.user?.active_plan?.id} className="cs_btn cs_style_1" style={{ "position": "unset", "marginTop": "10px", width: '100%', fontWeight: 'bold', fontSize: "16px" }}>
              <span>Go to Profile</span>
            </Link>
          </div>
        </div>
      </Modal>
      <Modal
        visible={showPopup}
        onOk={() => setShowPopup(false)}
        onCancel={() => setShowPopup(false)}
        footer={null}
      >
        <div className="cs_newsletter cs_style_1">
          <h2 className="cs_newsletter_title" style={{ fontSize: '22px' }}>Review your order</h2>
          <div className="cs_iconbox cs_style_4 d-flex align-items-center justify-content-between flex-1" style={{ padding: '15px', boxShadow: 'none', border: '0.1px solid #d7d7d7' }}>

            <h2 className="cs_iconbox_title cs_fs_15" style={{ color: '#000', fontSize: '18px' }}>{chosenPlan?.name}
              <br />
              <span style={{ fontWeight: 'normal', color: '#000', fontSize: 14 }}>{chosenPlan?.description}</span>
            </h2>
            <h2 className="cs_iconbox_title  cs_fs_32 m-0" style={{ color: '#000', fontSize: '20px' }}>₹ {chosenPlan?.price}</h2>
          </div>
          <form onSubmit={__applyCoupon}>
            <h3 className="cs_semibold cs_fs_24 mb-0" style={{ fontSize: '22px' }}>Enter Promo Code</h3>
            <span className="cs_fs_10" >
              Enter promo code if you have any
            </span>

            <div className="cs_newsletter_form">
              <div className='d-flex align-items-center'>
                {/* <div className={`${!isFocused ? 'fix-input' : 'fix-input-focus'}`}>+91</div> */}
                <input
                  type="text"
                  className="cs_form_field"
                  style={{ borderRadius: ' 2.4em ', paddingLeft: '10px' }}
                  placeholder="Enter Promo Code"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={promoCode}
                  required
                  onChange={(a) => {
                    setPromoCode(a.target.value)
                    setPromoError(false)
                  }}
                />
              </div>
              <button type='submit' className="cs_btn cs_style_1">
                <span>Apply</span>
                <i>
                  <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                  <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                </i>
              </button>
            </div>
            {discount > 0 && couponError == false &&
              <div style={{ fontWeight: 'bold', textAlign: 'center', padding: 10, color: 'green' }}>Coupon Applied</div>
            }

            {discount <= 0 && couponError == true && coupon_discount?.success == false &&
              <div style={{ fontWeight: 'bold', textAlign: 'center', padding: 10, color: 'red' }}>Invalid Coupon</div>
            }
          </form>
          <div className='' style={{ "marginTop": "20px" }}>
            <div className="cs_newsletter_subTitle d-flex align-items-start justify-content-between w-full">
              <span style={{ fontWeight: 'normal' }}>Sub Total</span>
              &nbsp;&nbsp;
              <span

                style={{ fontWeight: 'normal', }}>₹ {chosenPlan?.price}</span>
            </div>
            {/* <div className="cs_newsletter_subTitle d-flex align-items-start justify-content-between w-full">
              <span style={{ fontWeight: 'normal' }}>GST</span>
              &nbsp;&nbsp;
              <span

                style={{ fontWeight: 'normal', }}>₹ {(chosenPlan?.price * 18) / 100}</span>
            </div> */}

            {discount > 0 &&

              <div className="cs_newsletter_subTitle d-flex align-items-start justify-content-between w-full">
                <span style={{ fontWeight: 'normal' }}>Discount</span>
                &nbsp;&nbsp;
                <span

                  style={{ fontWeight: 'normal', color: 'green' }}>- ₹ {discount}</span>
              </div>
            }
            <div className="cs_newsletter_subTitle d-flex align-items-start justify-content-between w-full">
              <span style={{ fontWeight: 'bold', fontSize: "19px", color: '#D2538C', }}>Grand Total</span>
              &nbsp;&nbsp;
              <span

                style={{ fontWeight: 'bold', color: '#D2538C', fontSize: "19px" }}>₹{__getGrandTotal()}</span>
            </div>

            <button onClick={__checkout} className="cs_btn cs_style_1" style={{ "position": "unset", "marginTop": "50px", width: '100%', fontWeight: 'bold', fontSize: "16px" }}>
              <span>Proceed to payment</span>
              <i>
                <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
              </i>
            </button>
            <div className="cs_newsletter_subTitle d-flex justify-content-center" style={{ textAlign: 'center', alignItems: 'center' }}>
              <input type='checkbox' onChange={(value) => {
                setIsChecked(value.target.checked)
                setError(false)
              }} style={{ marginRight: '5px' }} /> I agree to the
              &nbsp;
              <a onClick={() => {
                setShowPopup(false)
                setShowTerms(true)
              }}
                style={{ color: '#D2538C' }}>Terms and Conditions.</a>
            </div>
            {error &&
              <div className='text-center text-danger'>Accept Terms and Conditions to complete the checkout</div>
            }
          </div>
        </div>
      </Modal>
      <Modal
        visible={showTerms}
        onOk={() => {
          setShowPopup(true)
          setShowTerms(false)
        }}
        onCancel={() => {
          setShowPopup(true)
          setShowTerms(false)
        }}
        footer={null}
      >
        <div>
          <h5 className='text-center'>Terms and Conditions</h5>
          <p>
            We acknowledge a cooling-off period of up to 30 days post-onboarding, within which customers may elect to continue or discontinue services. Full service access is granted during this period. Once a customer opts to continue, they are precluded from terminating services prematurely due to the reliance of the GetAFixMD product on a specified minimum customer group size. Additionally, our pricing strategy is formulated based on specific Minimum Order Quantities (MOQs) for GetAFixMD, ensuring better pricing for customers. Consequently, users/subscribers are...
            <a href='https://getafixmd.com/terms-and-conditions/' target='_blank' style={{ color: '#D2538C' }}> Read More</a>
          </p>
        </div>
      </Modal>
      <div className="row cs_gap_y_50">
        {plans?.filter((item) => item.plan_type != 'HEALTH CARE').map((item, index) => (
          <div className="col-xl-3" key={index}>
            <Pricing {...item}
              openPopUp={openPopUp}
              item={item}
            />
          </div>
        ))}
        {/* {plans?.filter((item) => item.plan_type != 'HEALTH CARE').length != 0 &&
          <div style={{ color: '#D2538C', display: 'flex' }}>
            <input
              type="checkbox"
              onChange={(a) => setShowHealthCare(a.target.checked)}
            />
            <div style={{ marginLeft: '10px' }}>Show Health care plans</div>
          </div>
        } */}
        {/* {(showHealthCare || plans?.filter((item) => item.plan_type != 'HEALTH CARE').length == 0) && plans?.filter((item) => item.plan_type == 'HEALTH CARE').map((item, index) => ( */}
        {plans?.filter((item) => item.plan_type != 'HEALTH CARE').length == 0 && plans?.filter((item) => item.plan_type == 'HEALTH CARE').map((item, index) => (
          <div className="col-xl-12" key={index}>
            <PricingHorizontal {...item}
              openPopUp={openPopUp}
              item={item}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
