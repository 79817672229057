import { useEffect, useState } from "react";
import Spacing from "../Spacing";
import StepOne from "../Steps/StepOne";
import StepTwo from "../Steps/StepTwo";
import StepThree from "../Steps/StepThree";
import StepFour from "../Steps/StepFour";
import StepPlan from "../Steps/StepPlan";
import { useDispatch, useSelector } from "react-redux";
import {
  addFamilyDetails,
  addHealthCondition,
  addPolicyDetails,
  addSpecificIllness,
  updateUserData,
} from "../../redux/user/userSlice";
import useAuthToken from "../../hooks/useAuthToken";
import { useNavigate } from "react-router-dom";
import Stepper from "../Stepper";
import axios from "axios";
import Departments from "./Departments";
import Doctors from "./Doctors";
import { set } from "date-fns";

const contactInfo = [
  { title: "Phone", subTitle: "+91 9995159567" },
  { title: "Email Us", subTitle: "info@getafixmd.com" },
  {
    title: "Our Location",
    subTitle:
      "Kerala Start Up Mission, 2 nd Floor Admin Block, Govt Polytechnic, Pollachi road, Koottupatha, Palakkad",
  },
];

const points = [3, 4.5, 5, 6];

export default function Onboarding() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const [loading, setLoading] = useState(false);
  const policy_details = useSelector((state) => state.user.policy_details);
  const family_details = useSelector((state) => state.user.family_details);
  const health_condition = useSelector((state) => state.user.health_condition);
  const specific_illness = useSelector((state) => state.user.specific_illness);
  const token = useAuthToken();

  const [viewContactInfo, setViewContactInfo] = useState(true);
  const [stepTwo, setStepTwo] = useState(true);
  const [stepThree, setStepThree] = useState(false);
  const [stepFour, setStepFour] = useState(false);
  const [stepFive, setStepFive] = useState(false);
  const [stepPlan, setStepPlan] = useState(false);

  const [error, setError] = useState(null);
  const [insured, setInsuredValue] = useState(user?.user?.is_insured_other ? 'YES' : 'NO');;
  const [policyNumber, setPolicyNumber] = useState(null);
  const [purchaseDate, setPurchaseDate] = useState(null);
  const [validUpto, setValidUpto] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [deductable, setDeductable] = useState(points[2]);

  const [isHealthy, setIsHealthy] = useState(user?.user?.is_healthy ? 'YES' : 'NO');
  const [isSpecificIllness, setIsSpecificIllness] = useState(user?.user?.is_specific_illness ? 'YES' : 'NO');
  const [otherDiseases, setOtherDiseases] = useState(user?.user?.other_diseases);

  const [branches, setBranches] = useState([]);
  const [doctors, setDoctors] = useState([]);

  const [familyDetails, setFamilyDetails] = useState({
    insuranceType: user?.user?.insurance_type ?? "SELF",
    email: user?.user?.email ?? "",
    district: "",
    type: "",
    place: "",
    primaryInsured: {
      dob: user?.user?.dob ?? "",
      gender: user?.user?.gender ?? "",
    },
    secondAdult: {
      dob: typeof(user?.user?.family_detials) == 'object' ? user?.user?.family_detials : JSON.parse(user?.user?.family_detials)?.['secondAdult']['dob'] ?? "",
      gender: typeof(user?.user?.family_detials) == 'object' ? user?.user?.family_detials : JSON.parse(user?.user?.family_detials)?.['secondAdult']['gender'] ?? "",
    },
    firstChild: {
      dob: typeof(user?.user?.family_detials) == 'object' ? user?.user?.family_detials : JSON.parse(user?.user?.family_detials)?.['firstChild']['dob'] ?? "",
      gender: typeof(user?.user?.family_detials) == 'object' ? user?.user?.family_detials : JSON.parse(user?.user?.family_detials)?.['firstChild']['gender'] ?? "",
    },
    secondChild: {
      dob: typeof(user?.user?.family_detials) == 'object' ? user?.user?.family_detials : JSON.parse(user?.user?.family_detials)?.['secondAdult']['dob'] ?? "",
      gender: typeof(user?.user?.family_detials) == 'object' ? user?.user?.family_detials : JSON.parse(user?.user?.family_detials)?.['secondAdult']['gender'] ?? "",
    },
  });

  useEffect(() => {
    if (user?.success && user?.user?.onboarding_step) {
      switch (user?.user?.onboarding_step) {
        case "2":
        case 2:
          setViewContactInfo(true);
          setStepTwo(true);
          setStepThree(false);
          setStepFour(false);
          setStepFive(false);
          setStepPlan(false);
          break;

        case "3":
        case 3:
          setViewContactInfo(true);
          setStepTwo(false);
          setStepThree(true);
          setStepFour(false);
          setStepFive(false);
          setStepPlan(false);
          break;

        case "4":
        case 4:
          setViewContactInfo(true);
          setStepTwo(false);
          setStepThree(false);
          setStepFour(true);
          setStepFive(false);
          setStepPlan(false);
          break;

        case "5":
        case 5:
          setViewContactInfo(true);
          setStepTwo(false);
          setStepThree(false);
          setStepFour(false);
          setStepFive(true);
          setStepPlan(false);
          break;

        case "6":
        case 6:
          setViewContactInfo(false);
          setStepTwo(false);
          setStepThree(false);
          setStepFour(false);
          setStepFive(false);
          setStepPlan(true);
          break;

        default:
          setViewContactInfo(true);
          setStepTwo(true);
          setStepThree(false);
          setStepFour(false);
          setStepFive(false);
          setStepPlan(false);
          break;
      }
    } else {
      // localStorage.setItem("popup", "true");
      // navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (policy_details?.success) {
      setViewContactInfo(false);
      setStepFive(false);
      setStepPlan(true);
      setLoading(false);
    }
  }, [policy_details]);

  useEffect(() => {
    if (family_details?.success) {
      // setViewContactInfo(true);
      // setStepTwo(false);
      // setStepThree(true);
      setLoading(false);
    }
  }, [family_details]);

  useEffect(() => {
    if (health_condition?.success) {
      if (isHealthy == "YES") {
        setStepThree(false);
        setStepFive(true);
      } else {
        setStepThree(false);
        setStepPlan(true);
      }
      setLoading(false);
    }
  }, [health_condition]);

  useEffect(() => {
    if (specific_illness?.success) {
      setStepFour(false);
      setStepFive(true);
      setLoading(false);
    }
  }, [specific_illness]);

  const goToStepTwo = () => {
    if (!insured) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
      return;
    }
    if (insured == "YES") {
      if (deductable) {
        setLoading(true);
        dispatch(
          addPolicyDetails({
            token: token,
            insured: insured,
            policyNumber: policyNumber,
            purchaseDate: purchaseDate,
            validUpto: validUpto,
            companyName: companyName,
            deductable: deductable,
          })
        );
      } else {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
        return;
      }
    } else {
      setLoading(true);
      dispatch(
        addPolicyDetails({
          token: token,
          insured: insured,
          policyNumber: policyNumber,
          purchaseDate: purchaseDate,
          validUpto: validUpto,
          companyName: companyName,
          deductable: deductable,
        })
      );
    }
  };

  const goToStepThree = () => {
    const {
      insuranceType,
      primaryInsured,
      secondAdult,
      // district,
      // type,
      // place,
    } = familyDetails;

    if (
      !primaryInsured.dob ||
      !primaryInsured.gender
      // !district ||
      // !type ||
      // !place
    ) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
      return;
    }

    if (insuranceType === "FAMILY") {
      if (!secondAdult.dob || !secondAdult.gender) {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
        return;
      }
    }

    setLoading(true);
    dispatch(
      addFamilyDetails({
        token: token,
        insuranceType: insuranceType,
        familyDetails: familyDetails,
      })
    );
  };

  const goToStepFour = () => {
    if (!isHealthy) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
      return;
    }
    setLoading(true);
    dispatch(
      addHealthCondition({
        token: token,
        isHealthy: isHealthy,
        otherDiseases: otherDiseases,
      })
    );
  };

  const goToStepPlan = () => {
    if (!isSpecificIllness) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
      return;
    }

    setLoading(true);
    dispatch(
      addSpecificIllness({
        token: token,
        isSpecificIllness: isSpecificIllness,
      })
    );
  };

  useEffect(() => {
    dispatch(updateUserData(token));
  }, []);

  const goToPreTwo = () => {
    setViewContactInfo(true);
    setStepTwo(true);
    setStepThree(false);
    setStepFour(false);
    setStepFive(false);
    setStepPlan(false);
  }

  const goToPreThree = () => {
    setViewContactInfo(true);
    setStepTwo(false);
    setStepThree(true);
    setStepFour(false);
    setStepFive(false);
    setStepPlan(false);
  }

  useEffect(() => {
    const sendRequest = async () => {
      try {
        const response = await axios.patch(
          "https://api.raphacure.co.in/api/v1/auth/signon",
          { patient_id: "HHGDJN56" },
          {
            headers: {
              "x-api-key":
                "K2MZa4RoalYZgAUlHdnqOmP91FDTe43qpaEz1EBfGqgf3bwz0jnLjEHMtFaHgiiO",
            },
          }
        );

        const getBranches = async () => {
          const config = {
            headers: {
              Authorization: `Bearer ${response?.data?.data?.accessToken}`,
            },
          };
          try {
            const response = await axios.get(
              "https://api.raphacure.co.in/api/v1/vendor?state=Kerala",
              config
            );

            setBranches(response?.data?.data?.vendors);
          } catch (err) {
            console.log(err);
          }
        };

        getBranches();

        const getDoctors = async () => {
          const config = {
            headers: {
              Authorization: `Bearer ${response?.data?.data?.accessToken}`,
            },
          };
          try {
            const response = await axios.get(
              "https://api.raphacure.co.in/api/v1/doctor?state=Kerala",
              config
            );
            setDoctors(response?.data?.data?.doctors);
          } catch (err) {
            console.log(err);
          }
        };

        getDoctors();
      } catch (err) {
        console.log(err);
      }
    };

    sendRequest();
  }, []);

  return (
    <>
      <Spacing md="170" />
      <div className="container">
        <div className="row">
          <div className={`${viewContactInfo ? "col-lg-12" : "col-lg-12"}`}>
            {stepTwo && (
              <>
                <h2 className="cs_fs_40 cs_medium mb-0">Onboarding</h2>
                <div className="cs_height_42 cs_height_xl_25" />
              </>
            )}
            {stepTwo && (
              <StepTwo
                error={error}
                loading={loading}
                familyDetails={familyDetails}
                setFamilyDetails={setFamilyDetails}
                goToStepThree={goToStepThree}
                user={user?.user}
              />
            )}
            {stepThree && (
              <StepThree
                error={error}
                loading={loading}
                setIsHealthy={setIsHealthy}
                otherDiseases={otherDiseases}
                setOtherDiseases={setOtherDiseases}
                goToStepFour={goToStepFour}
                goToPreTwo={goToPreTwo}
                user={user?.user}
              />
            )}
            {stepFour && (
              <StepFour
                error={error}
                loading={loading}
                isSpecificIllness={isSpecificIllness}
                setIsSpecificIllness={setIsSpecificIllness}
                goToStepPlan={goToStepPlan}
              />
            )}
            {stepFive && (
              <StepOne
                error={error}
                loading={loading}
                insured={insured}
                setInsuredValue={setInsuredValue}
                policyNumber={policyNumber}
                setPolicyNumber={setPolicyNumber}
                purchaseDate={purchaseDate}
                setPurchaseDate={setPurchaseDate}
                validUpto={validUpto}
                setValidUpto={setValidUpto}
                companyName={companyName}
                setCompanyName={setCompanyName}
                deductable={deductable}
                setDeductable={setDeductable}
                goToStepTwo={goToStepTwo}
                goToPreThree={goToPreThree}
              />
            )}
            {stepPlan && <StepPlan />}
          </div>
          {viewContactInfo && (
            <div className="col-lg-4 offset-lg-1">
              <div className="cs_height_lg_100" />

              {stepTwo && <Stepper index={0} />}
              {stepThree && <Stepper index={1} />}
              {stepFour && <Stepper index={2} />}
              {stepFive && <Stepper index={2} />}

              {/* <h2 className="cs_fs_40 cs_medium mb-0">Contact Info</h2>
                            <div className="cs_height_60 cs_height_xl_40" />
                            <img
                                src="/images/appointments/appointment_img.jpeg"
                                alt="Appointment"
                                className="cs_radius_25 w-100"
                            />
                            <div className="cs_height_100 cs_height_xl_60" />
                            <ul className="cs_contact_info cs_style_1 cs_mp0">
                                {contactInfo.map((item, index) => (
                                    <li key={index}>
                                        <h3 className="cs_fs_24 cs_semibold mb-0">{item.title}</h3>
                                        <p className="mb-0 cs_heading_color">{item.subTitle}</p>
                                    </li>
                                ))}
                            </ul> */}
            </div>
          )}
        </div>

        <Spacing md="150" lg="150" xl="150" />
        <Departments data={branches} />

        <Spacing md="100" lg="100" xl="100" />
        <Doctors doctors={doctors} />
      </div>
    </>
  );
}
