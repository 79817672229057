import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDistricts, getPlaces } from '../../redux/user/userSlice';
import Lottie from 'react-lottie';
import loading from '../../loading.json';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import { enGB } from 'date-fns/locale';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export default function StepTwo({
    error,
    loading,
    familyDetails,
    setFamilyDetails,
    goToStepThree,
    user
}) {
    const dispatch = useDispatch();
    const [districts, setDistricts] = useState([]);
    const [places, setPlaces] = useState([]);

    useEffect(() => {
        dispatch(getDistricts()).then((response) => {
            if (response.payload && response.payload?.success) {
                setDistricts(response.payload?.districts);
            }
        })
    }, [])

    const _getPlaces = (e) => {
        const { name, value } = e.target;

        setFamilyDetails({
            ...familyDetails,
            [name]: value
        });

        if (name == 'district') {
            if (familyDetails.type) {
                dispatch(getPlaces({ district_id: value, type: familyDetails.type })).then((response) => {
                    if (response.payload && response.payload?.success) {
                        setPlaces(response.payload?.places);
                    }
                })
            }
        } else {
            if (familyDetails.district) {
                dispatch(getPlaces({ district_id: familyDetails.district, type: value })).then((response) => {
                    if (response.payload && response.payload?.success) {
                        setPlaces(response.payload?.places);
                    }
                })
            }
        }
    }

    const handleTypeChange = (e) => {
        const { name, value } = e.target;
        setFamilyDetails({
            ...familyDetails,
            [name]: value
        });
    };

    const handleFamilyMemberChange = (key, e) => {
        const { name, value } = e.target;
        setFamilyDetails({
            ...familyDetails,
            [key]: {
                ...familyDetails[key],
                [name]: value
            }
        });
    };

    const handleDateChange = (key, date) => {
        setFamilyDetails({
            ...familyDetails,
            [key]: {
                ...familyDetails[key],
                dob: date
            }
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <label className="cs_input_label cs_heading_color">
                        Who is this insurance policy for?
                    </label>
                    <div className="cs_radio_group">
                        <div className="cs_radio_wrap">
                            <input
                                className="cs_radio_input"
                                type="radio"
                                name="insuranceType"
                                id="self"
                                checked={familyDetails['insuranceType'] == 'SELF'}
                                value={'SELF'}
                                onChange={(a) => handleTypeChange(a)}
                            />
                            <label className="cs_radio_label" htmlFor="self">
                                Just Myself
                            </label>
                        </div>
                        <div className="cs_radio_wrap">
                            <input
                                className="cs_radio_input"
                                type="radio"
                                name="insuranceType"
                                id="family"
                                checked={familyDetails['insuranceType'] == 'FAMILY'}
                                value={'FAMILY'}
                                onChange={(a) => handleTypeChange(a)}
                            />
                            <label className="cs_radio_label" htmlFor="family">
                                Myself and Family (2 Adults & 2 Children)
                            </label>
                        </div>
                    </div>
                    <div className="cs_height_42 cs_height_xl_25" />
                </div>
                <form className='row'>
                    <div className="col-lg-4">
                        <label className="cs_input_label cs_heading_color">
                            Your Date of Birth
                        </label>
                        <div className="cs_with_icon_input">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                <DatePicker
                                    label="DD/MM/YYYY"
                                    value={familyDetails.primaryInsured.dob}
                                    onChange={(date) => handleDateChange('primaryInsured', date)}
                                    // minDate={new Date("1900-01-01")}
                                    maxDate={new Date()}
                                    inputFormat="dd/MM/yyyy"
                                    views={['year', 'month', 'day']}

                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>
                    <div className="col-lg-4">
                        <label className="cs_input_label cs_heading_color">
                            Your Gender
                        </label>
                        <div className="cs_radio_group">
                            <div className="cs_radio_wrap">
                                <input
                                    className="cs_radio_input"
                                    type="radio"
                                    name="gender"
                                    id="male"
                                    value={'MALE'}
                                    checked={familyDetails['primaryInsured']['gender'] == 'MALE'}
                                    onChange={(e) => handleFamilyMemberChange('primaryInsured', e)}
                                />
                                <label className="cs_radio_label" htmlFor="male">
                                    Male
                                </label>
                            </div>
                            <div className="cs_radio_wrap">
                                <input
                                    className="cs_radio_input"
                                    type="radio"
                                    name="gender"
                                    id="female"
                                    value={'FEMALE'}
                                    checked={familyDetails['primaryInsured']['gender'] == 'FEMALE'}
                                    onChange={(e) => handleFamilyMemberChange('primaryInsured', e)}
                                />
                                <label className="cs_radio_label" htmlFor="female">
                                    Female
                                </label>
                            </div>
                        </div>
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>
                    <div className="col-lg-4">
                        <label className="cs_input_label cs_heading_color">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            className="cs_form_field"
                            placeholder="Enter your Email"
                            value={familyDetails.email}
                            onChange={(a) => handleTypeChange(a)}
                        />
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>
                </form>
                {familyDetails.insuranceType == 'FAMILY' &&
                    <>
                        <label className="cs_input_label cs_heading_color" style={{ fontWeight: 'bold' }}>
                            Family Members' Details
                        </label>
                        <form className='row'>
                            <div className="col-lg-6">
                                <label className="cs_input_label cs_heading_color">
                                    Second Adult Member's Date of Birth
                                </label>
                                <div className="cs_with_icon_input">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                        <DatePicker
                                            label="DD/MM/YYYY"
                                            value={familyDetails.secondAdult.dob}
                                            onChange={(date) => handleDateChange('secondAdult', date)}
                                            // minDate={new Date("1900-01-01")}
                                            maxDate={new Date()}
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="cs_height_42 cs_height_xl_25" />
                            </div>
                            <div className="col-lg-6">
                                <label className="cs_input_label cs_heading_color">
                                    Gender
                                </label>
                                <div className="cs_radio_group">
                                    <div className="cs_radio_wrap">
                                        <input
                                            className="cs_radio_input"
                                            type="radio"
                                            name="gender"
                                            id="male"
                                            value={'MALE'}
                                            checked={familyDetails['secondAdult']['gender'] == 'MALE'}
                                            onChange={(e) => handleFamilyMemberChange('secondAdult', e)}
                                        />
                                        <label className="cs_radio_label" htmlFor="male">
                                            Male
                                        </label>
                                    </div>
                                    <div className="cs_radio_wrap">
                                        <input
                                            className="cs_radio_input"
                                            type="radio"
                                            name="gender"
                                            id="female"
                                            value={'FEMALE'}
                                            checked={familyDetails['secondAdult']['gender'] == 'FEMALE'}
                                            onChange={(e) => handleFamilyMemberChange('secondAdult', e)}
                                        />
                                        <label className="cs_radio_label" htmlFor="female">
                                            Female
                                        </label>
                                    </div>
                                </div>
                                <div className="cs_height_42 cs_height_xl_25" />
                            </div>
                        </form>
                        <form className='row'>
                            <div className="col-lg-6">
                                <label className="cs_input_label cs_heading_color">
                                    First Child's Date of Birth
                                </label>
                                <div className="cs_with_icon_input">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                        <DatePicker
                                            label="DD/MM/YYYY"
                                            value={familyDetails.firstChild.dob}
                                            onChange={(date) => handleDateChange('firstChild', date)}
                                            // minDate={new Date("1900-01-01")}
                                            maxDate={new Date()}
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="cs_height_42 cs_height_xl_25" />
                            </div>
                            <div className="col-lg-6">
                                <label className="cs_input_label cs_heading_color">
                                    Gender
                                </label>
                                <div className="cs_radio_group">
                                    <div className="cs_radio_wrap">
                                        <input
                                            className="cs_radio_input"
                                            type="radio"
                                            name="gender"
                                            id="male"
                                            value={'MALE'}
                                            checked={familyDetails['firstChild']['gender'] == 'MALE'}
                                            onChange={(e) => handleFamilyMemberChange('firstChild', e)}
                                        />
                                        <label className="cs_radio_label" htmlFor="male">
                                            Male
                                        </label>
                                    </div>
                                    <div className="cs_radio_wrap">
                                        <input
                                            className="cs_radio_input"
                                            type="radio"
                                            name="gender"
                                            id="female"
                                            value={'FEMALE'}
                                            checked={familyDetails['firstChild']['gender'] == 'FEMALE'}
                                            onChange={(e) => handleFamilyMemberChange('firstChild', e)}
                                        />
                                        <label className="cs_radio_label" htmlFor="female">
                                            Female
                                        </label>
                                    </div>
                                </div>
                                <div className="cs_height_42 cs_height_xl_25" />
                            </div>
                        </form>
                        <form className='row'>
                            <div className="col-lg-6">
                                <label className="cs_input_label cs_heading_color">
                                    Second Child's Date of Birth
                                </label>
                                <div className="cs_with_icon_input">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                        <DatePicker
                                            label="DD/MM/YYYY"
                                            value={familyDetails.secondChild.dob}
                                            onChange={(date) => handleDateChange('secondChild', date)}
                                            // minDate={new Date("1900-01-01")}
                                            maxDate={new Date()}
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="cs_height_42 cs_height_xl_25" />
                            </div>
                            <div className="col-lg-6">
                                <label className="cs_input_label cs_heading_color">
                                    Gender
                                </label>
                                <div className="cs_radio_group">
                                    <div className="cs_radio_wrap">
                                        <input
                                            className="cs_radio_input"
                                            type="radio"
                                            name="gender"
                                            id="male"
                                            value={'MALE'}
                                            checked={familyDetails['secondChild']['gender'] == 'MALE'}
                                            onChange={(e) => handleFamilyMemberChange('secondChild', e)}
                                        />
                                        <label className="cs_radio_label" htmlFor="male">
                                            Male
                                        </label>
                                    </div>
                                    <div className="cs_radio_wrap">
                                        <input
                                            className="cs_radio_input"
                                            type="radio"
                                            name="gender"
                                            id="female"
                                            value={'FEMALE'}
                                            checked={familyDetails['secondChild']['gender'] == 'FEMALE'}
                                            onChange={(e) => handleFamilyMemberChange('secondChild', e)}
                                        />
                                        <label className="cs_radio_label" htmlFor="female">
                                            Female
                                        </label>
                                    </div>
                                </div>
                                <div className="cs_height_42 cs_height_xl_25" />
                            </div>
                        </form>
                    </>
                }
                {user?.customer_id?.length < 12 &&
                    <>
                        <div className="col-lg-4">
                            <label className="cs_input_label cs_heading_color">
                                District
                            </label>
                            <select
                                onChange={(a) => _getPlaces(a)}
                                name='district'
                                className='cs_form_field'>
                                <option selected disabled>Choose an Option</option>
                                {districts?.map((district, index) => (
                                    <option value={district?.id} key={index}>
                                        {district?.name}
                                    </option>
                                ))}
                            </select>
                            <div className="cs_height_42 cs_height_xl_25" />
                        </div>
                        <div className="col-lg-4">
                            <label className="cs_input_label cs_heading_color">
                                Type
                            </label>
                            <select
                                onChange={(a) => _getPlaces(a)}
                                name='type'
                                className='cs_form_field'>
                                <option selected disabled>Choose an Option</option>
                                <option value="PANCHAYAT">Grama Panchayat</option>
                                <option value="CORPORATION">Municipal Corporation</option>
                                <option value="MUNICIPALITY">Municipality</option>
                            </select>
                            <div className="cs_height_42 cs_height_xl_25" />
                        </div>
                        <div className="col-lg-4">
                            <label className="cs_input_label cs_heading_color">
                                Place
                            </label>
                            <select
                                onChange={(a) => handleTypeChange(a)}
                                name='place'
                                className='cs_form_field'>
                                <option selected disabled>Choose an Option</option>
                                {places?.map((place, index) => (
                                    <option value={place?.id} key={index}>{place?.name}</option>
                                ))}
                            </select>
                            <div className="cs_height_42 cs_height_xl_25" />
                        </div>
                    </>
                }
            </div>
            {loading ? (
                <button className="cs_btn cs_style_1">
                    <span>Continue</span>
                    <Lottie options={defaultOptions} width={45} />
                </button>
            ) : (
                <button className="cs_btn cs_style_1" onClick={goToStepThree}>
                    <span>Continue</span>
                    <i>
                        <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                        <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                    </i>
                </button>
            )}
            {error &&
                <div style={{ color: 'red', fontSize: '12px' }}>
                    Please complete the details
                </div>
            }
        </>
    );
}